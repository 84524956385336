import React from 'react';
import { Box, Flex } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import ClientHero from '../sections/client/ClientHero';
import SellingPoints from '../sections/client/SellingPoints';
import LatinTimeZone from '../sections/client/LatinTimeZone';
import Testimonials from '../sections/client/Testimonials';
import Companies from '../sections/client/Companies';
import TalentsSay from '../sections/client/TalentsSay';
import GetStarted from '../sections/client/GetStarted';
import IncRanking from '../sections/client/incRanking';
import SocialProof from '../sections/client/SocialProof';
import CompareHiringLatam from '../sections/client/CompareHiringLatam';

const HomePage = () => {
  return (
    <>
      <MetaData
        title='Hire the Right Team with Nexton'
        description='We connect US companies with the best engineers in Latin America through our talent network.'
      />
      {/* Home page is called client page */}
      <Layout selected='client'>
        <ClientHero />
        <SellingPoints />
        <LatinTimeZone />
        <Box className='full-width hero' bg='nextonLightBlue' style={{ zIndex: '-1' }}>
          <Testimonials />
        </Box>
        <Companies />
        <Box className='full-width' bg='nextonLightBlue'>
          <CompareHiringLatam />
        </Box>
        <TalentsSay />
        <Flex flexDirection='column' pb='60px' justifyContent='center' alignItems='center'>
          <Box mb='80px'>
            <SocialProof />
          </Box>
          <IncRanking />
        </Flex>
        <Box className='full-width' bg='nextonLightBlue'>
          <GetStarted />
        </Box>
      </Layout>
    </>
  );
};

export default HomePage;
