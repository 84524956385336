import React from 'react';
import { Flex, Text, Box } from 'rebass';
import WhiteRoundCard from '../../components/client/WhiteRoundCard';

import timezone from '../../../assets/images/client/latin-timezone/timezone.svg';
import talented from '../../../assets/images/client/latin-timezone/talented.svg';
import remote from '../../../assets/images/client/latin-timezone/remote.svg';
import longterm from '../../../assets/images/client/latin-timezone/longterm.svg';
import mission from '../../../assets/images/client/latin-timezone/mission.svg';
import culturalFit from '../../../assets/images/client/latin-timezone/cultural-fit.svg';

const FeatureBox = ({ image, title, description }) => (
  <Box maxWidth={['100%', '100%', '350px']} minWidth='33%' mt={['60px', '80px', '80px']} px={['10px', '15px', '25px']}>
    <Flex height='70px' alignItems='flex-end'>
      <Box>
        <img src={image} alt={title} />
      </Box>
    </Flex>
    <Text fontSize={['32px', '34px', '36px']} my={['15px', '20px', '20px']}>
      {title}
    </Text>
    <Text fontSize={['18px', '20px', '20px']} lineHeight='1.6'>
      {description}
    </Text>
  </Box>
);

const LatinTimeZone = () => (
  <Box my={['90px', '110px', '120px']}>
    <WhiteRoundCard padding='60px 40px'>
      <Text
        fontSize={['36px', '38px', '42px']}
        fontWeight='500'
        mt={['20px', '0px', '0px']}
        mb='30px'
        color='nextonDarkGrey'
        maxWidth='870px'
        px={['10px', '15px', '25px']}
        as='h3'>
        <Text as='span' color='secondary'>
          Latin America
        </Text>{' '}
        provides experienced talent for your company in the same{' '}
        <Text as='span' color='secondary'>
          timezone
        </Text>
      </Text>
      <Flex justifyContent='space-evenly' flexWrap='wrap'>
        <FeatureBox
          image={timezone}
          title='Your Timezone'
          description='Sharing the same longitude across the Americas, your team can have the same timezone or 2-5 hours difference.'
        />
        <FeatureBox
          image={talented}
          title='Incredibly Talented'
          description='Latin America provides highly educated, creative and ambitious Engineering Talent.'
        />
        <FeatureBox
          image={remote}
          title='Remote Experienced'
          description='Latin American Talent has many years of remote work experience which translates into a seamless transition to your team.'
        />
        <FeatureBox
          image={longterm}
          title='Long-Term Thinkers'
          description='Talent who want to build products and companies for the long term and have  opportunities to grow professionally.'
        />
        <FeatureBox
          image={mission}
          title='Mission Oriented'
          description='Engineers passionate to get involved in purposeful companies and change the world.'
        />
        <FeatureBox
          image={culturalFit}
          title='Great Cultural Fit'
          description='Motivated, competitive, diverse people with excellent English communication skills.'
        />
      </Flex>
    </WhiteRoundCard>
    <Box
      className='full-width'
      bg='nextonLightBlue'
      height='30vh'
      marginTop={['-30%', '-20%', '-10%']}
      style={{ zIndex: '-1', position: 'absolute', left: '0' }}
    />
  </Box>
);

export default LatinTimeZone;
