import React from 'react';
import { Flex, Text, Box } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';

import styled from 'styled-components';
import topiaLogo from '../../../assets/images/client/topia-logo.svg';
import newfrontLogo from '../../../assets/images/client/newfront-logo.svg';
import bravadoLogo from '../../../assets/images/client/Bravado.svg';
import updateAILogo from '../../../assets/images/client/UpdateAi.svg';
import tiaLogo from '../../../assets/images/client/Tia_Logo.svg';
import collectiveLogo from '../../../assets/images/client/Collective.svg';

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1500,
  pauseOnFocus: true,
  pauseOnHover: true,
  dots: true,
  dotsClass: 'slick-dots slick-thumb-mobile',
  customPaging: () => <span>dot</span>,
  arrows: false,
  slidesToShow: 1,
};

const QuotationTextOpen = styled(Text)`
  position: ${({ position = '100%' }) => position};
  height: ${({ height = '1px' }) => height};
  top: -10px;
  @media (max-width: 425px) {
    top: 0px;
  }
`;

const ProfileImage = styled(GatsbyImage)`
  border-radius: 50%;
  width: 218px;
  height: 218px;
`;

const Testimonials = () => {
  const { nishantProfile, gordonProfile, oliviaLeavengood, joshSchachter, ericDargelies, vicentWalker } =
    useStaticQuery(graphql`
      {
        nishantProfile: file(relativePath: { eq: "images/client/nishant-profile.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
        gordonProfile: file(relativePath: { eq: "images/client/gordon-profile.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
        oliviaLeavengood: file(relativePath: { eq: "images/client/olivia-profile.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
        joshSchachter: file(relativePath: { eq: "images/client/josh-profile.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
        ericDargelies: file(relativePath: { eq: "images/client/eric-profile.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
        vicentWalker: file(relativePath: { eq: "images/client/vicent-profile.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
      }
    `);

  const testimonialsList = [
    {
      name: 'Nishant Mittal',
      title: 'Senior Vice President & GM, Business Travel',
      companyLogo: topiaLogo,
      companyName: 'Topia',
      profilePicture: nishantProfile,
      testimony: `Nexton has access to the best talent in Latin America. They provide you the right fit for your team in less time than every other outsourcing company.`,
    },
    {
      name: 'Gordon Wintrob',
      title: 'Co-Founder & CTO',
      companyLogo: newfrontLogo,
      companyName: 'Newfront',
      profilePicture: gordonProfile,
      testimony: `Nexton is a supercharged recruiter who knows everything about the Latin American market and helps us quickly find, assess, and hire exceptional talent.`,
    },
    {
      name: 'Olivia Leavengood',
      title: 'Head of the Sales Team at Bravado',
      companyLogo: bravadoLogo,
      companyName: 'Bravado',
      profilePicture: oliviaLeavengood,
      testimony: `We hired over 30 people in about two months. We've worked with many different agencies at the beginning to hire all around the world and we found Nexton to be the best, the most professional, friendly and warm. We plan on continuing to hire through Nexton in the future.`,
    },
    {
      name: 'Josh Schachter',
      title: 'CEO of UptadeAI',
      companyLogo: updateAILogo,
      companyName: 'UptadeAI',
      profilePicture: joshSchachter,
      testimony: `It enabled me for my business to know that I could actually grow an entire unit out there with the foundation of knowing I’ll find the right people through Nexton at our side.`,
    },
    {
      name: 'Eric Dargelies',
      title: 'VP of Engineering at Tia',
      companyLogo: tiaLogo,
      companyName: 'Tia',
      profilePicture: ericDargelies,
      testimony: `Nexton’s process is amazing —probably one of the best recruiting processes I’ve been a part of. We don’t even have to look at resumes anymore because the candidates from Nexton always match what we’re looking for.`,
    },
    {
      name: 'Vicent Walker',
      title: 'VP of Talent at Collective',
      companyLogo: collectiveLogo,
      companyName: 'Collective',
      profilePicture: vicentWalker,
      testimony: `We’ve had good fortune working with Nexton. We’ll work with Nexton again because we’ve got a great hire experience, a great partnership, a great reporting and I think the relationship is strong.`,
    },
  ];

  return (
    <Box className='body-layout' my={['45px', '55px', '60px']}>
      <Slider {...settings}>
        {testimonialsList.map(testimonial => (
          <Box key={testimonial.title}>
            <Flex
              p={['10px', '30px', '50px']}
              pt={['18px', '40px', '70px']}
              mb='72px'
              flexWrap={['wrap', 'nowrap', 'nowrap']}
              alignItems='center'>
              <Box p='30px'>
                <Flex>
                  <QuotationTextOpen
                    fontSize={['40px', '90px']}
                    fontWeight='400'
                    marginRight={['5px', '20px']}
                    color='primary'
                    position='relative'>
                    “
                  </QuotationTextOpen>
                  <Text
                    fontSize={['18px', '20px', '24px']}
                    lineHeight={['30px', '38px', '44px']}
                    mb='30px'
                    fontStyle='italic'
                    maxWidth='870px'>
                    {testimonial.testimony}
                  </Text>
                </Flex>

                <Box ml={['22px', '56px']}>
                  <Flex mb='20px' flexDirection={['column', 'column', 'row']}>
                    <Text fontSize={['18px', '18px', '20px']} fontWeight='500'>
                      {testimonial.name}
                    </Text>
                    <Text display={['none', 'none', 'block']}>-</Text>
                    <Text fontSize={['18px', '18px', '20px']} mt={['5px', '5px', '0px']}>
                      {testimonial.title}
                    </Text>
                  </Flex>

                  <img
                    style={{ maxWidth: '104px', maxHeight: '24px' }}
                    src={testimonial.companyLogo}
                    alt={testimonial.companyName}
                  />
                </Box>
              </Box>

              <Box p='0px' minWidth='218px' height='218px' mx='auto'>
                <Box>
                  <ProfileImage
                    image={testimonial.profilePicture?.childImageSharp.gatsbyImageData}
                    alt={testimonial.name}
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Testimonials;
