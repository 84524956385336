import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const StyledWhiteRoundCard = styled(Box)`
  background-color: white;
  border: 1px solid #d0d0d0;
  border-radius: 24px;
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.16);

  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  padding: ${({ padding = '70px' }) => padding};
  margin: ${({ margin = 'auto' }) => margin};

  @media (max-width: 639px) {
    padding: 30px;
  }
`;

const WhiteRoundCard = ({ children, maxWidth, padding, margin }) => (
  <StyledWhiteRoundCard maxWidth={maxWidth} padding={padding} margin={margin}>
    {children}
  </StyledWhiteRoundCard>
);

export default WhiteRoundCard;
