import React from 'react';
import { Flex, Text, Box, Image } from 'rebass';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import WhiteRoundCard from '../../components/client/WhiteRoundCard';

import idCard from '../../../assets/images/client/id-card.svg';
import tightSlack from '../../../assets/images/client/tight-slack.svg';
import iterative from '../../../assets/images/client/iterative.svg';
import satisfaction from '../../../assets/images/client/satisfaction.svg';

// import angularLogo from '../../../assets/images/client/tech-logos/logo-angular-color.svg';
// import cLogo from '../../../assets/images/client/tech-logos/logo-c-color.svg';
// import jsLogo from '../../../assets/images/client/tech-logos/logo-javascript-color.svg';
// import pythonLogo from '../../../assets/images/client/tech-logos/logo-python-color.svg';
// import reactLogo from '../../../assets/images/client/tech-logos/logo-react-color.svg';

const angularLogo = '../../../assets/images/client/tech-logos/logo-angular-color.svg';
const cLogo = '../../../assets/images/client/tech-logos/logo-c-color.svg';
const jsLogo = '../../../assets/images/client/tech-logos/logo-javascript-color.svg';
const pythonLogo = '../../../assets/images/client/tech-logos/logo-python-color.svg';
const reactLogo = '../../../assets/images/client/tech-logos/logo-react-color.svg';

const SellingPoints = () => {
  const { idCardFront } = useStaticQuery(graphql`
    {
      idCardFront: file(relativePath: { eq: "images/client/id-card-front.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 603, height: 404, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  return (
    <>
      <Box my={['90px', '110px', '120px']}>
        <Flex flexWrap='wrap'>
          <Box width={[1, 1, 1 / 2]} p={['30px', '40px', '50px']}>
            <Text fontSize={['54px', '64px']} color='nextonBlue' as='h3' lineHeight='1.1'>
              <Text fontWeight='700' as='span'>
                100% control over{' '}
              </Text>
              <Text fontWeight='400' as='span'>
                your hiring process
              </Text>
            </Text>
            <Text
              fontSize={['30px', '32px', '34px']}
              fontWeight='500'
              lineHeight='1.3'
              mt='32px'
              mb='20px'
              color='nextonBlack'>
              Remove all the clutter from the recruiting process. Our track record is 5:1 submit to hire ratio.
            </Text>
            <Text fontSize={['18px', '20px']} lineHeight='1.5' letterSpacing='-0.5px' color='nextonDarkGrey'>
              We select a small group of sharp candidates interested in learning more about your company. You will only
              interview 3 to 8 carefully selected candidates, investing just the right amount of your time to find the
              ideal team member to join the company.
            </Text>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={['30px', '40px']} pb={['0px', '0px', '0px']} height='100%'>
            <Box style={{ position: 'relative', zIndex: '0' }}>
              <Box textAlign='center'>
                <Box
                  style={{
                    width: '100%',
                    maxWidth: 'calc(374px + 100px)',
                    position: 'relative',
                    zIndex: '-1',
                  }}>
                  <Fade duration={600}>
                    <Image
                      src={idCard}
                      alt='Candidates Card'
                      pl={['50px', '50px', '50px']}
                      pr={['50px', '50px', '50px']}
                    />
                  </Fade>
                </Box>
              </Box>
              <Box
                style={{
                  width: '100%',
                  maxWidth: 'calc(374px + 100px)',
                  position: 'absolute',
                  top: '14%',
                  left: '0px',
                  zIndex: '-1',
                }}>
                <Fade duration={600} delay={600}>
                  <Image
                    src={idCard}
                    alt='Candidates Card'
                    pl={['0px', '25px', '25px']}
                    pr={['100px', '75px', '75px']}
                  />
                </Fade>
              </Box>

              <Box
                style={{
                  width: '100%',
                  maxWidth: 'calc(374px + 100px)',
                  position: 'absolute',
                  top: '28%',
                  right: '0px',
                  zIndex: '-1',
                }}>
                <Fade duration={600} delay={1200}>
                  <Image
                    src={idCard}
                    alt='Candidates Card'
                    pl={['100px', '75px', '75px']}
                    pr={['0px', '25px', '25px']}
                  />
                </Fade>
              </Box>
              <Box textAlign='center'>
                <Fade duration={600} delay={1800}>
                  <Zoom duration={600} delay={1800}>
                    <GatsbyImage
                      image={idCardFront.childImageSharp.gatsbyImageData}
                      alt='Candidates Card Full'
                      style={{
                        width: '100%',
                        maxWidth: 'calc(545px + 100px)',
                        position: 'relative',
                        zIndex: '0',
                      }}
                    />
                  </Zoom>
                </Fade>
              </Box>
              {/* <StaticImage
            src="../../../assets/images/client/id-card.svg"
            alt="Candidates Card"
            layout="constrained"
            quality="95"
          /> */}
            </Box>
          </Box>
        </Flex>
        <Flex flexWrap='wrap' mb={['30px', '40px', '60px']}>
          <Box width={[1, 1, 1 / 2]} p={['30px', '40px']} color='nextonDarkGrey' order={['2', '2', '1']}>
            <Fade left duration={600} delay={0}>
              <WhiteRoundCard padding='20px' margin='15px auto'>
                <Flex alignItems='center'>
                  <Box width='80px' textAlign='center'>
                    <img src={tightSlack} alt='Tight Communication' />
                  </Box>
                  <Text fontSize={['18px', '28px', '28px']} fontWeight='500' ml='20px'>
                    Personalized end-to-end support
                  </Text>
                </Flex>
              </WhiteRoundCard>
            </Fade>
            <Fade left duration={600} delay={500}>
              <WhiteRoundCard padding='20px' margin='15px auto'>
                <Flex alignItems='center'>
                  <Box width='80px' textAlign='center'>
                    <img src={iterative} alt='Iterative and adaptive process' />
                  </Box>
                  <Text fontSize={['18px', '28px', '28px']} fontWeight='500' ml='20px'>
                    Iterative and adaptive process
                  </Text>
                </Flex>
              </WhiteRoundCard>
            </Fade>
            <Fade left duration={600} delay={1000}>
              <WhiteRoundCard padding='20px' margin='15px auto'>
                <Flex alignItems='center'>
                  <Box width='80px' textAlign='center'>
                    <img src={satisfaction} alt=' Satisfaction guaranteed' />
                  </Box>
                  <Text fontSize={['18px', '28px', '28px']} fontWeight='500' ml='20px'>
                    100% Satisfaction guaranteed
                  </Text>
                </Flex>
              </WhiteRoundCard>
            </Fade>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={['30px', '40px', '50px']} order={['1', '1', '2']}>
            <Text fontSize={['54px', '64px']} color='nextonBlue' as='h3' lineHeight='1.1' fontWeight='700'>
              Agile
            </Text>
            <Text
              fontSize={['30px', '32px', '34px']}
              fontWeight='500'
              lineHeight='1.3'
              mt='32px'
              mb='20px'
              color='nextonBlack'>
              A recruiting process created by engineers.
            </Text>
            <Text fontSize={['18px', '20px']} lineHeight='1.5' letterSpacing='-0.5px' color='nextonDarkGrey'>
              Our engineer-led recruiting team is sophisticated, quick, and efficient. We can complete a recruiting
              process from start to finish in 3 to 6 weeks (just 2 sprints). Both, companies and candidates, spend just
              the right amount of time in the recruiting process, saving you time and money.
            </Text>
          </Box>
        </Flex>
        <Flex flexWrap='wrap'>
          <Box width={[1, 1, 1 / 2]} p={['30px', '40px', '50px']}>
            <Text fontSize={['54px', '64px']} color='nextonBlue' as='h3' lineHeight='1.1' fontWeight='700'>
              Tech Experts
            </Text>
            <Text
              fontSize={['30px', '32px', '34px']}
              fontWeight='500'
              lineHeight='1.3'
              mt='32px'
              mb='20px'
              color='nextonBlack'>
              Focused in tech companies pushing the boundaries of innovation.
            </Text>
            <Text fontSize={['18px', '20px']} lineHeight='1.5' letterSpacing='-0.5px' color='nextonDarkGrey'>
              We are focused on <b>US based Startups</b> from Seed-stage to Unicorns in fast-growing industries (ML/AI
              applications for e-commerce, real-estate, beauty to IoT, space tech, and many more). We fulfill any type
              of tech role, across discipline, across seniority, with ultimate technologies.
            </Text>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={['30px', '40px']} color='nextonDarkGrey'>
            <Flex>
              <StaticImage src={angularLogo} alt='Candidates Card' layout='constrained' quality='95' />
              <StaticImage src={cLogo} alt='Candidates Card' layout='constrained' quality='95' />
              <StaticImage src={jsLogo} alt='Candidates Card' layout='constrained' quality='95' />
              <StaticImage src={pythonLogo} alt='Candidates Card' layout='constrained' quality='95' />
              <StaticImage src={reactLogo} alt='Candidates Card' layout='constrained' quality='95' />
            </Flex>
            {/* <Flex>
          <img src={angularLogo} alt="Angular" />
          <img src={cLogo} alt="C++" />
          <img src={jsLogo} alt="JavaScript" />
          <img src={pythonLogo} alt="Python" />
          <img src={reactLogo} alt="React" />
        </Flex> */}
            <WhiteRoundCard padding='35px 30px' margin='15px auto'>
              <Flex flexDirection='column'>
                <Text fontSize={['24px', '30px', '30px']} fontWeight='500' mb='30px'>
                  Across Discipline, Across Seniority
                </Text>
                <Flex
                  flexWrap='wrap'
                  justifyContent='space-between'
                  fontSize={['16px', '22px', '22px']}
                  lineHeight='1.6'>
                  {/* <Box>
                  <Text width={[1, 1 / 2, 1 / 2]}>Frontend Engineer</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>Dir. of Engineering</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>Software Engineer</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>Data Scientist</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>UX Designer</Text>
                </Box>
                <Box>
                  <Text width={[1, 1 / 2, 1 / 2]}>Backend Engineer</Text>
                  <Text color="nextonDarkGrey02">Fullstack Engineer</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>DevOps</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>QA Engineer</Text>
                  <Text width={[1, 1 / 2, 1 / 2]}>Product Manager</Text>
                </Box> */}
                  <Box width={[1 / 2, 1 / 2, 1 / 2]}>
                    <Text>Frontend Engineer</Text>
                    <Text>Dir. of Engineering</Text>
                    <Text>Software Engineer</Text>
                    {/* <Text color="nextonDarkGrey02">Data Scientist</Text> */}
                    <Text>Data Scientist</Text>
                    <Text>UX Designer</Text>
                  </Box>
                  <Box width={[1 / 2, 1 / 2, 1 / 2]} pl='10px'>
                    <Text>Backend Engineer</Text>
                    {/* <Text color="nextonDarkGrey02">Fullstack Engineer</Text> */}
                    <Text>Fullstack Engineer</Text>
                    <Text>QA Engineer</Text>
                    <Text>Product Manager</Text>
                    <Text>DevOps</Text>
                  </Box>
                </Flex>
              </Flex>
            </WhiteRoundCard>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default SellingPoints;
