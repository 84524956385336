import React from 'react';
import { Box, Text, Link, Button } from 'rebass';
import CompaniesLogos from '../../components/CompaniesLogos';
import VentureCapitalLogos from '../../components/VentureCapitalLogos';

const Companies = () => (
  <Box my={['90px', '110px', '120px']} textAlign='center'>
    <Text fontSize='24px' color='nextonDarkGrey' mb='33px' as='h3' fontWeight='400'>
      TRUSTED BY COMPANIES BACKED BY
    </Text>
    <VentureCapitalLogos />
    <Text fontSize='24px' color='nextonDarkGrey' mt='50px' mb='33px' as='h3' fontWeight='400'>
      TRANSFORMING TEAMS AT
    </Text>
    <CompaniesLogos />
    <Link href='https://talent.nextonlabs.com/tell-us-more-about-your-business'>
      <Button variant='newMainOrange'>SCHEDULE A CALL</Button>
    </Link>
  </Box>
);

export default Companies;
