import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import Fade from 'react-reveal/Fade';

const StyledBgImage = styled(BgImage)`
  background-size: contain;
  background-position: top right;

  &::before,
  &::after {
    @media screen and (max-width: 1330px) {
      background-size: cover !important;
      background-position: top center !important;
    }
  }
`;

const WorldMap = () => {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/client/hero/map04.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        }
        name
      }
    }
  `);

  return (
    <Fade duration={750} delay={500}>
      <Box
        style={{
          height: '100vh',
          width: '60%',
          position: 'absolute',
          top: '0',
          backgroundColor: 'white',
          right: '0',
        }}>
        <StyledBgImage image={file.childImageSharp.gatsbyImageData} alt='Image of a map'>
          <Box style={{ height: '100vh', width: '100%' }} />
        </StyledBgImage>
      </Box>
    </Fade>
  );
};

export default WorldMap;
