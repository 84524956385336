import React from 'react';
import { Flex, Box, Text } from 'rebass';

import Slider from 'react-slick';

const TalentsSayBox = ({ testimonial, name, job }) => (
  <Flex
    textAlign='left'
    p={['20px', '32px', '32px']}
    mx='5px'
    mt='15px'
    mb='72px'
    height='400px'
    style={{
      boxShadow: '0px 12px 30px -10px rgba(161, 161, 161, 0.16)',
      borderRadius: '16px',
      zIndex: '1',
    }}>
    <Text fontSize='90px' color='nextonBlue'>
      “
    </Text>
    <Flex flexDirection='column' mt='40px' alignItems='flex-start'>
      <Text fontSize={['18px', '20px', '20px']} lineHeight='1.5' letterSpacing='-0.5px' fontStyle='italic' mb='24px'>
        {testimonial}
      </Text>
      <Text fontSize={['16px', '18px', '18px']} fontWeight='700' mb='2px'>
        {name}
      </Text>
      <Text fontSize={['14px', '16px', '16px']} fontWeight='400'>
        {job}
      </Text>
    </Flex>
  </Flex>
);

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1500,
  pauseOnFocus: true,
  pauseOnHover: true,
  dots: true,
  dotsClass: 'slick-dots slick-thumb-mobile',
  customPaging: () => <span>dot</span>,
  arrows: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1330,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
};

const TalentsSay = () => (
  <Box mt={['90px', '110px', '120px']} mb='150px' textAlign='center'>
    <Text fontSize='24px' color='nextonDarkGrey' mb='50px' as='h3' fontWeight='400'>
      TEAM MEMBERS HIRED BY OUR CLIENTS SAY
    </Text>
    <Box>
      <Slider {...settings}>
        <TalentsSayBox
          testimonial='Nexton gave me support over all the hiring process. Really good people with deep knowledge about what they do. Thanks!'
          name='Fernando Sellecchia'
          job='IBH - Data Manager'
        />
        <TalentsSayBox
          testimonial='Nexton is fresh air for Latin America developers looking to work remotely for top-notch US companies. I am super happy with them and trust they will keep helping me find the best jobs out there for quite a while.'
          name='Jonathan Chiocchio'
          job='Ordergroove - Sr. Backend Engineer'
        />
        <TalentsSayBox
          testimonial="Nexton perfectly balances the needs of a candidate with the company's needs. They see the team first, and they make sure each process becomes a dialogue between parties."
          name='Nicolás Martinez'
          job='AspireIQ - Sr. Engineering Manager'
        />
        <TalentsSayBox
          testimonial='I got a job through Nexton that is a perfect fit with my profile, based on technologies and skills that the Company needed. Everything went smooth and easy, Nexton gave me all the support and information needed for the position.'
          name='Agustín Jaime Barcia'
          job='Aprende.com - QA Tester'
        />
      </Slider>
    </Box>
  </Box>
);

export default TalentsSay;
