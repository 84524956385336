import React from 'react';
import { useMediaQuery } from 'react-responsive';

import WorldMap from '../../components/client/WorldMap';
import HeroTitle from '../../components/client/HeroTitle';

const ClientHero = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });

  return (
    <>
      <div
        style={{
          height: '100vh',
          marginTop: '-100px',
          width: '100%',
          position: 'relative',
        }}>
        {isDesktopOrLaptop && <WorldMap />}
        <HeroTitle />
      </div>
    </>
  );
};

export default ClientHero;
