import React from 'react';
import { Flex, Text, Box, Button } from 'rebass';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';

const CompareHiringLatam = () => {
  const { worldTwoCountries } = useStaticQuery(graphql`
    {
      worldTwoCountries: file(relativePath: { eq: "images/client/world-2-locations.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });
  return (
    <Box className='body-layout'>
      <Flex
        flexWrap='wrap'
        mt='10px'
        px={['10px', '30px', '50px']}
        py={['30px', '30px', '50px']}
        justifyContent={['center', 'center', 'space-around']}
        alignItems='center'>
        <Box width={[1, 3 / 4, 2 / 3, 3 / 4]}>
          <Text
            fontSize={['22px', '30px', '30px', '49px']}
            as='h2'
            lineHeight='1.1'
            textAlign={['center', 'center', 'left']}>
            <Text fontWeight='400' as='span' color='nextonBlue'>
              Optimize your budget planning with{' '}
            </Text>
            <Text fontWeight='600' as={isDesktopOrLaptop ? 'h4' : 'span'} color='nextonOrange' mt='10px'>
              Nexton’s Team Cost Calculator
            </Text>
          </Text>

          <Text
            mt='18px'
            fontSize={['17px', '22px', '19px', '29px']}
            as='h3'
            lineHeight='1.1'
            textAlign={['center', 'center', 'left']}
            fontWeight='500'
            color='nextonBlue'>
            Evaluate and manage the cost of hiring your dream tech team
          </Text>
          {!isDesktopOrLaptop && (
            <Box width='244px' marginX='auto' marginTop='25px' marginBottom='10px'>
              <GatsbyImage
                image={worldTwoCountries.childImageSharp.gatsbyImageData}
                alt='World Two Countries Connected'
              />
            </Box>
          )}

          <Flex mt={['15px']} justifyContent={['center', 'center', 'left']}>
            <GatsbyLink to='https://www.nextonlabs.com/calculate-team-costs/?utm_source=website&utm_medium=banner&utm_campaign=team_cost_calculator_banner&utm_id=team_cost_calculator_banner'>
              <Button
                variant='newMainOrangeLessPadding'
                mt={['12px', '24px']}
                mb={['12px', '0px']}
                height={['48px', '55px']}>
                Calculate
              </Button>
            </GatsbyLink>
          </Flex>
        </Box>
        {isDesktopOrLaptop && (
          <Box width='244px'>
            <GatsbyImage
              image={worldTwoCountries.childImageSharp.gatsbyImageData}
              alt='World Two Countries Connected'
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default CompareHiringLatam;
