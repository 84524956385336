import React from 'react';
import { Flex, Text, Box, Button, Link } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import Fade from 'react-reveal/Fade';
import './style.scss';

const HeroTitle = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });

  return (
    <Box
      style={{
        position: 'absolute',
        top: '0',
      }}>
      <Flex
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='flex-start'
        mt={['170px', 'calc(50vh - 200px)', 'calc(50vh - 150px)']}
        ml={['0px', '20px', '20px']}>
        <Flex>
          <Fade delay={1000} duration={1333}>
            <Text
              fontSize={['34px', '50px', '64px']}
              color='nextonBlue'
              style={{ textShadow: '0px 0px 5px #ffffff', fontWeight: '500' }}
              as='h1'>
              Build Your Dream Team
            </Text>
          </Fade>
        </Flex>
        <Flex>
          <Fade delay={1500} duration={1333}>
            <Text
              fontSize={['34px', '50px', '64px']}
              color='nextonOrange'
              mt={['0px', '-18px', '-5px']}
              style={{ textShadow: '0px 0px 5px #ffffff', fontWeight: '500' }}>
              in LATAM
            </Text>
          </Fade>
        </Flex>
        {isDesktopOrLaptop ? (
          <Flex flexDirection='column' mt='32px' width={['100%', '80%', '38%']}>
            <Fade delay={2000} duration={1333}>
              <Text
                fontSize={['16px', '18px', '20px']}
                textAlign='center'
                as='span'
                fontWeight='400'
                color='nextonDarkGrey'
                lineHeight='30px'>
                We empower U.S. companies to{' '}
                <strong>
                  build top-tier remote teams in Latin America with 100% control over their hiring process.&nbsp;
                </strong>
                Our expertise allows us to find and recruit top-skilled tech and non-tech talents to join first-class
                distributed teams.
              </Text>
            </Fade>
          </Flex>
        ) : (
          <Flex mt='32px' width={['100%', '80%', '60%']}>
            <Fade delay={2000} duration={1333}>
              <Text
                fontSize={['16px', '18px', '20px']}
                textAlign='center'
                as='span'
                fontWeight='400'
                color='nextonDarkGrey'
                lineHeight='30px'>
                We empower U.S. companies to{' '}
                <strong>
                  build top-tier remote teams in Latin America with 100% control over their hiring process.&nbsp;
                </strong>
                Our expertise allows us to find and recruit top-skilled tech and non-tech talents to join first-class
                distributed teams.
              </Text>
            </Fade>
          </Flex>
        )}
        <Flex flexDirection={['column', 'row']}>
          <Fade delay={2500} duration={1333}>
            <Link href='https://talent.nextonlabs.com/tell-us-more-about-your-business'>
              <Button variant='newMainOrangeLessPadding' mt='24px' mr='37px' height={['48px', '55px']}>
                SCHEDULE A CALL
              </Button>
            </Link>
            <Link href='https://www.nextonlabs.com/calculate-team-costs/?utm_source=website&utm_medium=hero&utm_campaign=hero_banner&utm_id=hero_banner'>
              <Button variant='newOutlineBlue' mt='24px' mr='37px' height={['48px', '55px']}>
                CALCULATE TEAM COSTS
              </Button>
            </Link>
          </Fade>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeroTitle;
